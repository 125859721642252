(function(){
  var form = document.getElementById('offer-form');
  var submitButton = document.getElementById('offer-form-submit');
  var overlay = document.getElementById('overlay');
  var timer = null;
  form.onsubmit = function(event) {
    event.preventDefault();
    submitButton.disabled = true;
    var req = new XMLHttpRequest();
    req.open('POST', form.getAttribute('action'), true);
    req.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
    req.onreadystatechange = function() {
      if (req.readyState == XMLHttpRequest.DONE) {
        if (req.status == 200) {
          overlay.className = "visible";
          timer = setTimeout(function() {
            overlay.className = "";
            timer = null;
            submitButton.disabled = false;
          }, 4000);
        } else {
          submitButton.disabled = false;
          alert('Coś poszło nie tak. Najprawdopodobniej to nie nasza wina… Prosimy, napiszesz maila na my@staszek.asia?');
        }
      }
    }
    var formData = "";
    var inputs = form.getElementsByTagName('input');
    for(var i = 0; i < inputs.length; i += 1) {
      if (inputs[i].name) {
        if (i > 0) {
          formData += '&';
        }
        formData += encodeURIComponent(inputs[i].name);
        formData += '=';
        formData += encodeURIComponent(inputs[i].value);
      }
    }
    req.send(formData);
  }
})();
